 /* .slider-container {
    position: relative;
    height: 100%;
    width: 100%; */
    /* position: relative; */
    /* width: fit-content; */
    /* position: absolute;
    top: calc(50px + 50px);
    margin-bottom: 20px; */
    /* height: 100%;
    width: 100%; */
    /* display: contents;
    justify-content: center;
    align-items: center;
    z-index: 2;
  } */

  /* .slider-container {
    position: absolute;
    /* display: flex; */
    /* flex-wrap: wrap; */
    /* flex-direction: column; */
    /* top: 100%; */
    /* margin: 110 auto; */
    /* z-index: 2; */
  /* } */
  
   

  /* .slider-container {
      position: absolute;
      margin: 0 auto;
      width: auto;
      height: auto; */
      /* overflow: hidden; */
      /* max-width: 500px; */
    /* }  */

  /* .slider {
    white-space: nowrap;
  } */

  .slider {
    position:relative;
    /* top: 0px; */
  }

  .slideshow-image {
    position: absolute;
    display: inline-block;
    max-width: 100%;
    /* object-fit: fill; */
    opacity: 0;
    transition: opacity 5s ease-in-out;
    /* vertical-align: middle; */
    /* border-radius: 40px; */
    border-style: hidden;
  } 
  
  /* .slideshow-image {
    position: absolute;
    height: auto;
    width: auto;
    display: inline-flex;
    opacity: 0;
    object-fit: scale-down;
    transition: opacity 1s ease-in-out;
    vertical-align: middle;
  } */

  .slideshow-image.active {
    opacity: 1;
  }

  @media (min-width:280px) and (max-width: 768px) {
    .slider {
      position:relative;
      top: 50px;
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    .slider {
      position:relative;
      top: 0px;
    }
  }