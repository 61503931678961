.photo-grid-container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.photo-grid {
    position: relative;
    display: grid;
    max-width: 90%;
    max-height: fit-content;
    margin: 0 auto;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }
  
  .photo-grid img {
    width: 100%;
    height: auto;
  }

  .fullscreen-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 9999;
    /* object-fit: contain; */
  }
  
  .fullscreen {
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: zoom-out;
  }  
  
  /* .fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.8);
    object-fit: contain;
  } */
  

  /* @media (min-width:320px) and (max-width: 480px) {
    .photo-grid-container {
      position: relative;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }
    .photo-grid {
      display: grid;
      max-width: 90%;
      margin: 0 auto;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 10px;
    }
    
    .photo-grid img {
      width: 100%;
      height: auto;
    }
  }
  @media (min-width:480px) and (max-width: 600px) {
    .photo-grid-container {
      position: relative;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }
    .photo-grid {
      display: grid;
      max-width: 90%;
      margin: 0 auto;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 10px;
    }
    
    .photo-grid img {
      width: 100%;
      height: auto;
    }
  }
  @media (min-width:600px) and (max-width: 768px) {
    .photo-grid-container {
      position: relative;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }
    .photo-grid {
      display: grid;
      max-width: 90%;
      margin: 0 auto;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 10px;
    }
    
    .photo-grid img {
      width: 100%;
      height: auto;
    }
  }
  @media (min-width:768px) and (max-width: 1024px) {
    .photo-grid-container {
      position: relative;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }
    .photo-grid {
      display: grid;
      max-width: 90%;
      margin: 0 auto;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 10px;
    }
    
    .photo-grid img {
      width: 100%;
      height: auto;
    }
  }
  @media (min-width:1024px) and (max-width: 1200px) {
    .photo-grid-container {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }
    .photo-grid {
      display: grid;
      max-width: 90%;
      margin: 0 auto;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 10px;
    }
    
    .photo-grid img {
      width: 100%;
      height: auto;
    }
  } */